:root {
  --primary: #910000;
  --secondary-01: #f6eee5;
  --secondary-02: #e33434;
  --secondary-03: #c70000;
  --secondary-04: #5e0000;
  --white: #fff;
  --white-secondary: #f6eee5;
  --white-secondary-15: #f6eee526;
  --white-secondary-50: #f6eee580;
  --neutral-darkGrey: #212121;
  --neutral-darkGrey-70: #212121b2;
  --neutral-black: #181818;

  --header-nav: 72px;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  font-family: 'Public Sans';
  background-color: var(--neutral-black);
  overflow-x: hidden;
}

a {
  color: var(--neutral-black);
  text-decoration: none;
}

.opacity--0 {
  opacity: 0;
}

.fade-in-animation {
  animation: fadeIn 1s ease-in-out forwards;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f6eee5;
  border-radius: 4px;
}

@keyframes slideRightFadeIn {
  from {
    opacity: 0;
    margin-right: -10px;
  }
  to {
    opacity: 1;
    margin-right: 0px;
  }
}

@keyframes slideLeftFadeOut {
  from {
    opacity: 1;
    margin-right: 0px;
  }
  to {
    opacity: 0;
    margin-right: -10px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* App.css */
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}

.slide-exit {
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}
