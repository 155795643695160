.icon {
  display: inline-block;
  width: 0;
  margin-left: 4px;
  opacity: 0;
  transition: width 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

button:hover .icon {
  height: 12px;
  width: 24px;
  opacity: 1;
}

.btn-mobile {
  background-color: var(--primary);
  color: white;
  border: 1px solid var(--primary);
}
.btn-mobile:hover {
  border: 1px solid var(--primary);
}

.btn-mobile .icon {
  width: 24px;
  opacity: 1;
}

@media screen and (min-width: 744px) {
  button:hover .icon {
    width: 48px;
    height: 24px;
  }
}
