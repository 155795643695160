#contact {
  max-width: 1064px;
  margin: 0 auto;
}

.contact-top {
  padding-top: 100px;
  padding-left: 25px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--white);
  transition: all 0.3s ease-out;
}

.contact-top .contact-heading {
  margin-top: 20px;
  margin-left: -20px;
  font-size: 64px;
  transition: all 0.3s ease-out;
}

.contact-sent-message {
  padding: 60px;
  transition: padding 0.1s ease-in-out;
}

.contact-sent-message .heading {
  font-size: 35px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: -0.022em;
  text-align: center;
}

.box-social {
  padding: 10px;
  border: 1px solid var(--white);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 744px) {
  .contact-top {
    padding-top: 96px;
    padding-left: 58px;
    padding-bottom: 40px;
  }
  .contact-sent-message {
    padding: 132px 132px 200px 132px;
  }
}

@media screen and (min-width: 1024px) {
  .contact-top {
    padding-left: 50px;
    padding-bottom: 40px;
  }
  .contact-top .contact-heading {
    margin-top: 40px;
    margin-left: 45px;
    font-size: 80px;
    line-height: 80px;
  }
}

@media screen and (min-width: 1440px) {
  .contact-top {
    padding-bottom: 40px;
  }
  .contact-top .contact-heading {
    margin-left: 48px;
  }
  .contact-sent-message .heading {
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.022em;
  }
}
