.textfield{
    color: black;
}

.textfield textarea{
    color: black;
}

.select-box {
    margin-bottom: 15px;
    color:black !important;
}
.select-box .MuiSelect-nativeInput{
    color: black;
}
.menu-item{
    color: black;
}

.select-multi {
    margin-bottom: 15px;
    color:black !important;
}
.MuiMenu-list{
    color: black;
}
.select-multi .MuiSelect-nativeInput{
    color: black;
}


