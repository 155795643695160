.thumbnail-collection {
  z-index: 0;
  position: relative;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.thumbnail-collection:hover {
  z-index: 1;
  transform: scale(1.3);
  border: 1px solid var(--primary);
  box-shadow: 0px 40px 40px 0px rgba(246, 238, 229, 0.15);
}

.thumbnail-collection .overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  width: 100%;
  height: 60px;
  background: var(--white-secondary-50);
  backdrop-filter: blur(4px);
  color: var(--neutral-black);
}

.thumbnail-collection .overlay .title {
  font-family: 'Public Sans';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.022em;
}

.thumbnail-collection .overlay .description {
  display: none;
  transition: all 0.3s ease-in-out;
  opacity: 0.6;
  margin-top: 8px;
}

.thumbnail-collection:hover .overlay {
  height: 100px;
  display: block;
  background: var(--white-secondary);
  color: var(--primary);
}

.thumbnail-collection:hover .overlay .title {
  margin-top: 28px;
  font-family: 'Cormorant Garamond';
  font-size: 19.2px;
  font-weight: 500;
  line-height: 19.2px;
}

@media screen and (min-width: 744px) {
  .thumbnail-collection:hover .overlay {
    height: 174px;
  }

  .thumbnail-collection:hover .overlay .title {
    margin-top: 8px;
    font-size: 32px;
    line-height: 32px;
  }

  .thumbnail-collection:hover .overlay .description {
    display: block;
    color: var(--neutral-darkGrey);
  }
}

@media screen and (min-width: 1024px) {
  .thumbnail-collection:hover .overlay {
    height: 186px;
  }
}
