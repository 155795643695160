.popup-info-product {
  padding: 0px;
  width: 100%;
  max-height: 932px;
}

.popup-info-product .info-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.popup-info-product .info-card .close-icon {
  z-index: 99;
  position: absolute;
  top: 16px;
  right: 16px;
}

.popup-info-product .info-card .info-image {
  height: 433px;
  object-fit: contain;
  background: #0b0b0b;
}

.popup-info-product .info-card .info-content {
  padding: 16px 32px;
}

@media screen and (min-width: 744px) {
  .popup-info-product {
    max-height: 1133px;
  }
}

@media screen and (min-width: 1024px) {
  .popup-info-product {
    max-height: 800px;
  }
  .popup-info-product .info-card {
    flex-direction: row;
  }
  .popup-info-product .info-card .info-image {
    flex: 1 0 50%;
    height: 100%;
  }
  .popup-info-product .info-card .info-content {
    flex: 1 0 50%;
    padding: 36px 30.72px;
  }
}

@media screen and (min-width: 1440px) {
  .popup-info-product .info-card .info-content {
    padding: 36px;
  }
}
