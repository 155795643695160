.product-top {
  padding-top: 100px;
  padding-left: 25px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--white);
  transition: all 0.3s ease-out;
}

.product-top .product-heading {
  margin-top: 20px;
  margin-left: -20px;
  font-size: 64px;
  transition: all 0.3s ease-out;
}

.category-container {
  width: 100%;
  max-width: 83%;
  margin-left: auto;
  margin-right: auto;
}

.category-item {
  opacity: 1;
  cursor: pointer;
  padding: 6px 16px 8px 16px;
  transition: opacity 0.3s ease-out;
}

.category-item:not(.active):hover {
  opacity: 0.5;
}

.list-product {
  max-width: 100%;
}

@media screen and (min-width: 744px) {
  .product-top {
    padding-top: 96px;
    padding-left: 58px;
    padding-bottom: 40px;
  }
  .category-container {
    max-width: 79%;
  }
}

@media screen and (min-width: 1024px) {
  .product-top {
    padding-left: 50px;
    padding-bottom: 40px;
  }
  .product-top .product-heading {
    margin-top: 40px;
    margin-left: 45px;
    font-size: 80px;
    line-height: 80px;
  }
  .category-container {
    max-width: 100%;
  }
  .list-product {
    width: 100%;
    max-width: 800px;
  }
}

@media screen and (min-width: 1440px) {
  .product-top {
    padding-bottom: 40px;
  }
  .product-top .product-heading {
    margin-left: 48px;
  }
  .list-product {
    width: 100%;
    max-width: 1200px;
  }
}
