.itemMenu.active {
    background-color: rgba(145, 0, 0.1); 
    color:white;/* Light gray background for active items */
}

.itemMenu{
    margin-bottom: 7px;
}

.itemMenu.active .iconMenu{
    color: white;
}

.itemMenu:hover .iconMenu{
    color:white
}