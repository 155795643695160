.title {
  font-family: 'Cormorant Garamond';
  line-height: 110%;
}

.subTitle {
  font-size: 56px;
}

.MuiCard-root {
  border-radius: 0px !important;
}

.MuiBreadcrumbs-separator {
  color: var(--white);
  margin: 0px 8px;
}

@media screen and (min-width: 744px) {
  .MuiBreadcrumbs-separator {
    margin: 0px 16px;
  }
}
