.banner {
  background: linear-gradient(
      180deg,
      rgba(24, 24, 24, 0) 0%,
      rgba(24, 24, 24, 0.6) 60%,
      #181818 100%
    ),
    url('../../assets/Banner2.png') rgba(211, 211, 211, 0.5) no-repeat center
      center/cover;
  width: 100%;
  padding: 0px;
  height: 100vh;
  max-height: 640px;
  padding-top: 151px;
  padding-bottom: 267px;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.banner .wrapper {
  width: 80vw;
  max-width: 1200px;
  margin: 0px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: margin 0.3s ease-in-out;
}

.banner .heading {
  font-size: 56px;
  line-height: 56px;
  letter-spacing: -0.022em;
  white-space: nowrap;
}

.banner .wrapper .content {
  max-width: 100%;
}

.second-section {
  padding-top: 200px;
  transition: padding-top 0.3s ease-in-out;
}

@media screen and (min-width: 744px) {
  .banner {
    display: flex;
    align-items: center;
    max-height: 720px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .banner .wrapper {
    gap: 32px;
  }
  .banner .heading {
    font-size: 91.2px;
    font-weight: 600;
    line-height: 91.2px;
  }
  .banner .wrapper .content {
    max-width: 75%;
  }
  .banner .wrapper .content > p {
    font-size: 22px;
    line-height: 30.8px;
  }
}

@media screen and (min-width: 1024px) {
  .banner {
    max-height: 800px;
  }
  .banner .wrapper {
    margin: 0px 104px;
  }
  .banner .wrapper .content {
    max-width: 65%;
  }
  .second-section {
    padding-top: 320px;
  }
  .collection-section {
    max-width: 800px;
    width: 100%;
    margin: 0px auto;
  }
}

@media screen and (min-width: 1366px) {
  .collection-section {
    max-width: 1200px;
  }
}

@media screen and (min-width: 1440px) {
  .banner {
    max-height: 900px;
  }
  .banner .wrapper {
    max-width: 1200px;
    margin: 0px auto;
  }
  .banner .wrapper .content {
    max-width: 45%;
  }
}

.bg-arrow {
  width: 80px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 120;
}

.icon-hover {
  display: none;
}

.bg-arrow:hover {
  background-color: #ffffffcc;
}

.bg-arrow:hover .icon-hover {
  display: block;
}

.bg-arrow:hover .icon-white {
  display: none;
}
