.layoutBackground {
  background: var(--neutral-black);
}

.navContainer {
  background-color: var(--neutral-darkGrey-70);
  padding: 16px 32px;
  border-bottom: 1px solid var(--white);
  backdrop-filter: blur(20px);
  max-height: var(--header-nav);
  justify-content: center;
}

.navItem {
  cursor: pointer;
  color: var(--white);
  margin-bottom: -1px;
  border-bottom: 1px solid transparent;
  transition: color 0.1s ease-in, border-bottom 0.5s ease-in-out;
}

.navItem:hover {
  color: var(--secondary-03);
  border-bottom: 1px solid var(--secondary-03);
}

.toggleNavItems {
  cursor: pointer;
  transition: transform 0.6s;
  height: 40px;
  width: 40px;
}

.toggleNavItems:hover {
  transform: rotate(-180deg);
}

.open {
  transform: rotate(45deg) !important;
}

.stay-up {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.1s ease-in-out;
}

.stay-up .heading {
  font-size: 35px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: -0.022em;
  text-align: center;
}

.footer {
  display: flex;
  padding: 32px;
  border-bottom: 1px solid var(--white);
  border-top: 1px solid var(--white);
  transition: padding 0.1s ease-in-out;
}

.copyright {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 32px 32px;
  transition: padding 0.1s ease-in-out;
}

@media screen and (min-width: 450px){
  .footer {
    padding: 60px;
  }

  .copyright{
    padding: 32px 60px;
  }

}

@media screen and (min-width: 768px) {
  .stay-up {
    margin: 200px 122px;
  }
}

@media screen and (min-width: 1024px) {
  .toggleNavItems {
    height: 50px;
    width: 50px;
  }
  .footer {
    padding: 60px 120px;
  }
  .copyright {
    padding: 32px 132px;
  }
}

@media screen and (min-width: 1440px) {
  .stay-up .heading {
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.022em;
  }
  .copyright {
    padding: 32px 120px;
  }
}

.itemMenu {
  cursor: pointer;
  font-size: 30px;
  color: black;
}

.itemMenu:hover {
  background-color: brown;
}

.itemMenu:hover .textMenu {
  color: white;
}
