.category-top {
  padding-top: 100px;
  padding-left: 25px;
  padding-bottom: 68px;
  border-bottom: 1px solid var(--white);
  transition: all 0.3s ease-out;
}

.category-top .category-heading {
  margin-top: 40px;
  margin-left: -56px;
  transition: all 0.3s ease-out;
}

.category-container {
  width: 100%;
  max-width: 83%;
  margin-left: auto;
  margin-right: auto;
}

.category-item {
  opacity: 1;
  cursor: pointer;
  padding: 6px 16px 8px 16px;
  transition: opacity 0.3s ease-out;
}

.category-item.active {
  background: var(--secondary-01);
  color: var(--neutral-darkGrey);
}

.category-item:not(.active):hover {
  opacity: 0.5;
}

.list-product {
  max-width: 100%;
  margin: 0px auto;
}

@media screen and (min-width: 744px) {
  .category-top {
    padding-top: 96px;
    padding-left: 58px;
  }
  .category-top .category-heading {
    margin-top: 40px;
    margin-left: -56px;
  }
  .category-container {
    max-width: 79%;
  }
}

@media screen and (min-width: 1024px) {
  .category-top {
    padding-left: 50px;
    padding-bottom: 40px;
  }
  .category-top .category-heading {
    margin-top: 40px;
    margin-left: -48px;
  }
  .category-container {
    max-width: 100%;
  }
  .list-product {
    max-width: 83%;
  }
}

@media screen and (min-width: 1440px) {
  .category-top {
    padding-bottom: 40px;
  }
  .category-top .category-heading {
    margin-top: 40px;
    margin-left: 4px;
  }
}
