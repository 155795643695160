.drawer-container {
  height: 100%;
  background-color: rgba(33, 33, 33, 0.3);
  padding: 16px 32px;
  border-bottom: 1px solid var(--white);
  backdrop-filter: blur(10px);
  text-align: right;
  display: flex;
  justify-content: space-between;
}

.drawer .MuiPaper-root  {
  background-color: transparent ;
  
}

.drawer-container .close-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: var(--white);
  padding: 16px 32px;
}

.drawer-container .close-icon button {
  width: 32px;
  height: 32px;
  color: var(--white);
  cursor: pointer;
}

.drawer-menu-container {
  padding: 0px;
}

.drawer-menu-container .menu-item {
  border-radius: 0px;
  border-bottom: 1px solid transparent;
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  width: fit-content;
}

.drawer-menu-container .menu-item.active {
  color: var(--secondary-03);
  border-bottom: 1px solid var(--secondary-03);
  font-size: 65px;
}

.drawer-menu-container .menu-item:hover {
  color: var(--secondary-03);
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid var(--secondary-03);
  font-size: 65px;
}
