.popup__content {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.popup__header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 24px;
  position: absolute;
  z-index: 99;
}
.popup__footer {
  display: flex;
  width: 100%;
  padding: 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.popup__body {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  overflow: auto;
}
