.details-top {
  padding-top: 100px;
  padding-left: 25px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--white);
  transition: all 0.3s ease-out;
}

.details-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-left: 0;
  padding-right: 0;
}

.details-wrapper .image-container {
  width: 100%;
  background-color: black;
}

.details-wrapper .details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
}

.details-container .details-name {
  font-size: 35px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: -0.022em;
}

.details-container .details-price {
  font-size: 40px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: -0.022em;
  color: var(--secondary-02);
}

.details-container .details-specs {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.022em;
}

.details-container .details-note {
  opacity: 0.8;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.022em;
}

.details-container .details-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;

}

@media screen and (min-width: 744px) {
  .details-top {
    padding-top: 96px;
    padding-left: 58px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .details-top {
    padding-left: 50px;
    padding-bottom: 40px;
  }
  .details-wrapper {
    gap: 0px;
    flex-direction: row;
    align-items: flex-start;
  }

  .details-wrapper .image-container {
    width: 60%;
  }

  .details-wrapper .details-container {
    width: 40%;
    padding-top: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .details-top {
    padding-bottom: 40px;
  }
}
