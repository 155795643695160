.dialog-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: var(--neutral-darkGrey);
  transition: all 0.3s ease-in-out;
}

.dialog-container .close-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 16px;
  padding-right: 9px;
}

.dialog-container .close-icon button {
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: var(--white);
}

.dialog-container .background-menu {
  display: none;
}

.dialog-container .content {
  flex: 1 0 100%;
}

.dialog-container .content .content-menu {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  padding-right: 32px;
  transition: padding-right 0.3s ease-in-out;
}

.menu-heading {
  color: var(--secondary-02);
  margin-bottom: 16px;
  text-align: right;
  transition: all 0.3s ease-in-out;
}

.menu-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;
}

.menu-container .menu-item {
  width: 80%;
  text-align: right;
  cursor: pointer;
  padding: 4px 8px;
  color: var(--white);
  transition: all 0.3s ease-in-out;
}

.menu-container .menu-item:hover,
.menu-container .menu-item.active {
  background-color: var(--secondary-03);
}

@media screen and (min-width: 744px) {
  .menu-heading {
    margin-bottom: 24px;
    font-size: 79.2px;
    font-weight: 500;
    line-height: 79.2px;
  }

  .menu-container .menu-item {
    font-size: 39.6px;
    font-weight: 500;
    line-height: 39.6px;
    padding: 8px;
  }
}

@media screen and (min-width: 1024px) {
  .dialog-container .background-menu {
    display: flex;
    flex: 1 0 50%;
  }
  .dialog-container .content {
    flex: 1 0 50%;
  }
  .dialog-container .content .content-menu {
    width: 100%;
    max-width: 392px;
    padding-right: 0px;
  }
  .menu-container .menu-item {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .dialog-container .content .content-menu {
    width: 83.35%;
    max-width: none;
  }
}
